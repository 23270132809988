import React from 'react'
import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';

import { SlidingArrowButton } from 'components/CustomButton';
import logo from '../../assets/blue_arrow.svg'

const useStyles = makeStyles((theme) => ({
    container : {
        marginTop : theme.spacing(2),
        width : "100%",
        display : 'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection : 'column'
    },
    boxContainer : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center',
        backgroundColor : '#C3F0FA',
        width : '100%',
        gap : theme.spacing(10),
        padding: theme.spacing(15, 37.5),
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(10, 15),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(10, 10),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(10, 5),
        },
    },
    boxContainerBottom : {
        display : 'flex',
        marginTop : theme.spacing(2),
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center',
        backgroundColor : '#FFDB96',
        width : '100%',
        gap : theme.spacing(15),
        padding: theme.spacing(15, 37.5),
        [theme.breakpoints.down("lg")]: {
          padding: theme.spacing(10, 15),
        },
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(10, 10),
        },
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(10, 5),
        },
    },
    upperContent : {
        display : 'flex',
        flexDirection : 'column',
        gap : '32px',
        justifyContent : 'center',
        alignItems :'center'
    }
}))

const points = [
  "I feel nervous about my interviews and I don't want to have low confidence anymore.",
  "I am not confident about my communication skills and want to improve.",
  "I'm not sure how exactly to improve my interview answers and don't have anyone to give me detailed feedback.",
  "I have been rejected by interviewers and not sure what I am doing wrong.",
  "I want to practice questions based on my resume and JD, not generic interview questions."
];

const B2cConfusedSection = () => {
    const classes = useStyles();

  return (
    <Box className={classes.container}>
        <Box className={classes.boxContainer}>
          <Box className={classes.upperContent}>
            <Typography variant='h2-bold' color='b2c.dark-900' sx={{textAlign : 'center'}}>
              Don’t Overthink & miss career opportunities due to lack of preparation.
            </Typography>
            <SlidingArrowButton
                label="Start Preparing Now"
                bgColor='#1961BF'
                variant="outlined"
                width="291px"
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  const element = document.getElementById("pricing");
                  if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                  }
              }}
            />
          </Box>
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap='4px'>
              <Typography variant='body01-bold' color='secondary.clr-900'>
                Full refund within 24 hours, no questions asked.
              </Typography>
              <Box
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  "&:hover img": {
                    transform: "rotate(45deg)", 
                    transition: "transform 0.3s ease", 
                  },
                }}
              >
                <a href={`${process.env.REACT_APP_ASSETS_URL}/pdf/languify-refund-policy-v2.pdf`} style={{textDecoration : 'none'}}>
                  <Typography variant="body01-link" color="b2c.blue-500">
                    Click to know more
                  </Typography>
                </a>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    transition: "transform 0.3s ease", 
                  }}
                />
              </Box>
          </Box>
        </Box>
        <Box className={classes.boxContainerBottom}>
          <Box display='flex' flexDirection='column' gap='8px'>
            <Typography variant='h2-bold' color='neutral.clr-900'>
               Still Confused, Is this gonna help YOU?
            </Typography>
            <Box>
              <Typography variant='h5-bold' color='neutral.clr-700'>
                If your answer is YES to any of these three or more statements,
              </Typography> &nbsp;
              <Typography variant='h5-bold' color='success.clr-700'>
                then YES. It is for YOU :)
              </Typography>
              </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            {points.map((point, index) => (
              <Typography
                key={index}
                variant="h5-semiBold"
                color="neutral.clr-900"
                sx={{ textIndent: '-1.5em', paddingLeft: '1.5em' }}
              >
                {index + 1}. {point}
              </Typography>
            ))}
          </Box>
          <Box display='flex' flexDirection='column' gap='16px'>
            <Box display='flex' flexDirection='column' gap='8px' alignItems='center'>
              <Typography variant='h4-bold' color='neutral.clr-900'>Then WHAT?</Typography>
              <Typography variant='h6-bold' color='neutral.clr-700'>Found it relevant for you!!</Typography>
            </Box>
            <SlidingArrowButton
                label="Get Interview Wizard Now!"
                bgColor='#1961BF'
                variant="outlined"
                width="343px"
                sx={{ textTransform: 'none' }}
                onClick={() => {
                  const element = document.getElementById("pricing");
                  if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                  }
              }}
            />
          </Box>
        </Box>
    </Box>
  )
}

export default B2cConfusedSection