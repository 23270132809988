import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
  },
  carousel: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(13),
    [theme.breakpoints.down("md")]: {
      gap: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      gap: "30px",
    },
  },
  clientImage: {
    flexShrink: 0,
    width: "140px",
    height: "100px",
    objectFit: "contain",
  },
  scrollLeft: {
    animation: "$scrollLeft 120s linear infinite",
    [theme.breakpoints.down("md")]: {
      animation: "$scrollLeft 15s linear infinite",
    },
    [theme.breakpoints.down("sm")]: {
      animation: "$scrollLeft 10s linear infinite",
    },
    "&:hover": {
      animationPlayState: "paused",
    },
  },
  scrollRight: {
    animation: "$scrollRight 120s linear infinite",
    [theme.breakpoints.down("md")]: {
      animation: "$scrollRight 15s linear infinite",
    },
    [theme.breakpoints.down("sm")]: {
      animation: "$scrollRight 10s linear infinite",
    },
    "&:hover": {
      animationPlayState: "paused",
    },
  },
  "@keyframes scrollLeft": {
    "0%": {
      transform: "translateX(0)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
  "@keyframes scrollRight": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(0)",
    },
  },
}));

const ClientCarousel = ({ images, direction = 'left' }) => {
  const classes = useStyles();
  const [duplicatedImages, setDuplicatedImages] = useState([]);

  useEffect(() => {
    // Creating array with 4 copies of images for loop effect
    const multipliedImages = [...images, ...images, ...images, ...images];
    setDuplicatedImages(multipliedImages);
  }, [images]);

  const carouselContent = (
    <>
      {duplicatedImages.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`client-${index}`}
          className={classes.clientImage}
        />
      ))}
    </>
  );

  return (
    <Box className={classes.container}>
      <Box 
        className={`${classes.carousel} ${
          direction === 'left' ? classes.scrollLeft : classes.scrollRight
        }`}
      >
        {carouselContent}
      </Box>
    </Box>
  );
};

export default ClientCarousel;