import React, { useState } from 'react'
import { makeStyles } from "@mui/styles";
import { Box, IconButton, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import CustomButton  from 'components/CustomButton';
import logo from "../../assets/languify_logo_black.svg";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    parentContainer : {
        position : 'fixed',
        width : '100%',
        zIndex : 10
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '88px',
        backgroundColor: '#FFFFFFE5',
        borderBottomLeftRadius: theme.spacing(5),
        borderBottomRightRadius: theme.spacing(5),
        padding: theme.spacing(0, 6),
        width: '100%',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderBottomLeftRadius: theme.spacing(5),
          borderBottomRightRadius: theme.spacing(5),
          border: '1px solid transparent',
          background: 'linear-gradient(180deg, #2D3142 0%, #737DA8 100%) border-box',
          mask: `linear-gradient(#fff 0 0) padding-box, 
                 linear-gradient(#fff 0 0)`,
          maskComposite: 'exclude',
          WebkitMask: `linear-gradient(#fff 0 0) padding-box, 
                       linear-gradient(#fff 0 0)`,
          WebkitMaskComposite: 'source-out',
          pointerEvents: 'none'
        },
      },
      innerContainer : {
        display : 'flex', 
        justifyContent : 'space-between', 
        alignItems : 'center', 
        gap : '40px',
        [theme.breakpoints.down("lg")]: {
            display : 'none'
        }
      },
    headerLinks : {
        textDecoration : 'none',
        color : '#2D3142',
        '&:hover' : {
           color : '#1961BF'
        }
    },
    innerMobileContainer : {
        display: 'flex',
        flexDirection : 'column',
        justifyContent: 'space-between',
        gap : theme.spacing(10),
        height: '100%',
        backgroundColor: '#FFFFFFE5',
        borderBottomLeftRadius: theme.spacing(5),
        borderBottomRightRadius: theme.spacing(5),
        padding: theme.spacing(6, 6),
        width: '100%',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderBottomLeftRadius: theme.spacing(5),
          borderBottomRightRadius: theme.spacing(5),
          border: '1px solid transparent',
          background: 'linear-gradient(180deg, #2D3142 0%, #737DA8 100%) border-box',
          mask: `linear-gradient(#fff 0 0) padding-box, 
                 linear-gradient(#fff 0 0)`,
          maskComposite: 'exclude',
          WebkitMask: `linear-gradient(#fff 0 0) padding-box, 
                       linear-gradient(#fff 0 0)`,
          WebkitMaskComposite: 'source-out',
          pointerEvents: 'none'
        },
    },
    mobileMenuIcon: {
        display: 'none',
        [theme.breakpoints.down("lg")]: {
          display: 'flex',
          color: '#2D3142',
        },
      },
      mobileCloseIcon: {
        display: 'none',
        [theme.breakpoints.down("lg")]: {
          display: 'flex',
          color: '#2D3142',
        },
      },
}))

const headerLinks = [
    {
    _id : 'interviewTypes',
    linkName : 'How it works'
    },
    {
    _id : 'pricing',
    linkName : 'Pricing'
    },
    {
    _id : 'faqs',
    linkName : 'FAQs'
    }
]

const B2cHeader = () => {
    const classes = useStyles();
    const navigate = useNavigate()

    const [openNav , setOpenNav] = useState(false)

  return (
    <Box className={classes.parentContainer}>
         
         {/* Desktop Nav bar */}
         {!openNav && (
            <Box className={classes.container}>
            <Link to="/b2c" style={{ textDecoration: "none" }}>
                <img src={logo} alt="languify" className={classes.logo} />
            </Link>
            <Box className={classes.innerContainer}>
                <Box display='flex' gap='44px'>
                    {headerLinks.map((link , index) => (
                        <a key={index} href={`#${link._id}`} className={classes.headerLinks}>
                            <Typography variant='h6-semiBold'>{link.linkName}</Typography>
                        </a>
                    ))}
                </Box>
                <Box display='flex' gap='12px'>
                    <CustomButton
                        size='large'
                        variant='contained'
                            sx={{
                                width : '169px',
                                height : '56px !important',
                                backgroundColor : 'transparent',
                                '&:hover': {
                                    backgroundColor: '#F5F9FF !important', 
                                },
                            }}
                        onClick={() => window.location.href = 'https://interview.languify.in/languify/login'}
                    >
                        <Typography variant='h6-medium' color = '#1961BF'> Log in</Typography>
                        
                    </CustomButton>
                    <CustomButton
                        size='large'
                        variant='contained'
                            sx={{
                                width : '169px',
                                height : '56px !important',
                                backgroundColor : '#1961BF'
                            }}
                        onClick={() => window.open(process.env.REACT_APP_RAZORPAY_PAYMENT_LINK_PLUS, '_self')}
                    >
                        <Typography variant='h6-medium'>Buy now</Typography>
                        
                    </CustomButton>
                    <CustomButton
                        size='large'
                        variant='outlined'
                            sx={{
                                width : '169px',
                                height : '56px !important',
                                borderColor : '#1961BF !important'
                            }}
                        onClick={() => navigate('/')}
                    >
                        <Typography variant='h6-medium'>For business</Typography>
                        
                    </CustomButton>

                </Box>
            </Box>
            <IconButton
                className={classes.mobileMenuIcon}
                onClick={()=> setOpenNav(true)}
            >
                <MenuIcon />
            </IconButton>
            </Box>
         )}
       
       
       {/* Mobile Navbar */}
       {openNav && (
        <Box className={classes.innerMobileContainer}>
            <Box display='flex' justifyContent='space-between'>
                <Link to="/b2c" style={{ textDecoration: "none" }}>
                    <img src={logo} alt="languify" className={classes.logo} />
                </Link>
                <IconButton
                    className={classes.mobileCloseIcon}
                    onClick={()=> setOpenNav(false)}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box display='flex' flexDirection='column' gap='20px' marginLeft='8px'>
                <Box display='flex' gap='28px' flexDirection='column'>
                    {headerLinks.map((link , index) => (
                        <a key={index} href={`#${link._id}`} className={classes.headerLinks}>
                            <Typography variant='h6-semiBold'>{link.linkName}</Typography>
                        </a>
                    ))}
                </Box>
                <Box display='flex' gap='12px' flexDirection='column'>
                <CustomButton
                    size='large'
                    variant='contained'
                        sx={{
                            width : '100%',
                            height : '56px !important',
                            backgroundColor : 'transparent',
                            '&:hover': {
                                backgroundColor: '#F5F9FF !important', 
                            },
                        }}
                    onClick={() => {
                        window.location.href = 'https://interview.languify.in/languify/login'
                        setOpenNav(false)
                        }}
                >
                    <Typography variant='h6-medium' color = '#1961BF'> Log in</Typography>
                    
                </CustomButton>
                <CustomButton
                    size='large'
                    variant='contained'
                        sx={{
                            width : '100%',
                            height : '56px !important',
                            backgroundColor : '#1961BF'
                        }}
                    onClick={() => {
                        window.open(process.env.REACT_APP_RAZORPAY_PAYMENT_LINK_PLUS, '_self')
                        setOpenNav(false)
                    }}
                >
                    <Typography variant='h6-medium'>Buy now</Typography>
                    
                </CustomButton>
                <CustomButton
                    size='large'
                    variant='outlined'
                        sx={{
                            width : '100%',
                            height : '56px !important',
                            borderColor : '#1961BF !important'
                        }}
                    onClick={() => navigate('/')}
                >
                    <Typography variant='h6-medium'>For business</Typography>
                    
                </CustomButton>
            </Box>
            </Box>
        </Box>
       )}
    </Box>
  )
}

export default B2cHeader