export const studenstTestimonial = [
  {
    _id : 1,
    studentName : 'Medhavi Malik',
    institueName : 'MBA Student at KJ Somaiya',
    testimonial : `I highly recommend this tool! I’ve already shared it with a couple of friends, and
                    they loved it. It boosts confidence and provides valuable feedback on responses. 
                    The example answers are a great addition, showing where I was right and what I could improve,
                    including technical terms recruiters expect. Overall, it’s an excellent resource for interview preparation!`
  },
  {
    _id : 2,
    studentName : 'Suraj',
    institueName : 'MBA Student at SPJIMR',
    testimonial : `This tool is fantastic for refining your thought process! It doesn't just provide 
                    answers but enhances them with technical terms expected by recruiters, helping you
                    deliver more complete responses. The feedback is invaluable, boosting your confidence 
                    and ensuring continuous improvement. I've already recommended it to friends-it's definitely 
                    worth trying!`
  },
  {
    _id : 3,
    studentName : 'Pratik',
    institueName : 'MBA Student at KJ Somaiya',
    testimonial : `This tool is a must-have for online interview preparation! It creates a real interview-like environment, helping you handle stress 
                    and refine your articulation. The tailored questions, detailed feedback, and sample answers were incredibly useful, boosting my 
                    confidence and helping me improve with every practice session. It's highly effective -I've even recommended it to others, and many 
                    have succeeded using it.`
  }
]

  export const productBasedImages = [
    'https://assets.languify.in/images/amazon.svg',
    'https://assets.languify.in/images/google.svg',
    'https://assets.languify.in/images/meta.svg',
    'https://assets.languify.in/images/netflix.svg',
    'https://assets.languify.in/images/spotify.svg',
  ];

  export const serviceBasedImages = [
    'https://assets.languify.in/images/infosys.svg',
    'https://assets.languify.in/images/tcs.svg',
    'https://assets.languify.in/images/capgemini.svg',
    'https://assets.languify.in/images/accenture.svg',
    'https://assets.languify.in/images/wipro.svg',
  ];

  export const clientImages = [
    'https://assets.languify.in/images/SPJ_b2c.png',
    'https://assets.languify.in/images/Aisect_b2c.png',
    'https://assets.languify.in/images/IIMU_b2c.png',
    'https://assets.languify.in/images/IIDE_b2c.png',
    'https://assets.languify.in/images/Somaiya_b2c.png',
  ]
  
  export const useCase = [
  {
      content : 'Final-year students preparing for placements can practice personalized mock interviews based on company job descriptions and their own resumes.',
      img : 'https://assets.languify.in/images/b2cRaj.svg'
  },
  {
      content : 'Got the interview call but feel unprepared? Get interview-ready with AI-powered Interview Wizard designed just for you!',
      img : 'https://assets.languify.in/images/b2cRaj1.svg'
  }
]

  export const mockSteps = [
  {
    name: "Job description & Resume",
    title : 'Company & Resume based Personalized Interview',
    steps: [
      {
        title :  "Select Job Details",
        img : 'https://assets.languify.in/images/JD_Resume_1.svg'
      },
      {
        title :   "Select Interview Round",
        img : 'https://assets.languify.in/images/JD_Resume_2.svg'
      },
      {
        title :   "Select Interviewer for Questions Difficulty",
        img : 'https://assets.languify.in/images/JD_Resume_3.svg'
      },
      {
        title :  "Upload Your Resume",
        img : 'https://assets.languify.in/images/JD_Resume_4.svg'
      },
      {
        title :  "Preview Details & Begin Interview",
        img : 'https://assets.languify.in/images/JD_Resume_5.svg'
      },           
    ],
  },
  {
    name: "Job description only",
    title : 'Company focused Interview without Resume',
    steps: [
      {
        title :  "Select Job Details",
        img : 'https://assets.languify.in/images/JD_1.svg'
      },
      {
        title :   "Select Interview Round",
        img : 'https://assets.languify.in/images/JD_2.svg'
      },
      {
        title :   "Select Interviewer for Questions Difficulty",
        img : 'https://assets.languify.in/images/JD_3.svg'
      },
      {
        title :  "Preview Details & Begin Interview",
        img : 'https://assets.languify.in/images/JD_4.svg'
      },           
    ],
  },
  {
    name: "Resume only",
    title : 'Job Role focused Interview based on Resume only',
    steps: [
      {
        title :  "Select Job Details",
        img : 'https://assets.languify.in/images/Resume_1.svg'
      },
      {
        title :   "Select Interview Round",
        img : 'https://assets.languify.in/images/Resume_2.svg'
      },
      {
        title :   "Select Interviewer for Questions Difficulty",
        img : 'https://assets.languify.in/images/Resume_3.svg'
      },
      {
        title :  "Upload Your Resume",
        img : 'https://assets.languify.in/images/Resume_4.svg'
      },
      {
        title :  "Preview Details & Begin Interview",
        img : 'https://assets.languify.in/images/Resume_5.svg'
      },        
    ],
  },
  {
    name: "Report based",
    title : "Practice Interview Questions based on Report",
    steps: [
      {
        title :   "Select Report details",
        img : 'https://assets.languify.in/images/Report_1.svg'
      },
      {
        title : "Select Interview Round",
        img : 'https://assets.languify.in/images/Report_2.svg'
      },
      {
        title : "Add Report (Upload PDF/Paste Content)",
        img : 'https://assets.languify.in/images/Report_3.svg'
      },
      {
        title : "Select Interviewer for Questions Difficulty",
        img : 'https://assets.languify.in/images/Report_4.svg'
      },
      {
        title : "Preview Details & Begin Interview",
        img : 'https://assets.languify.in/images/Report_5.svg'
      },    
    ],
  },
];

 export const feedbackSteps = [{
  name : 'Feedback Steps',
  title : 'Students will get Feedback over 15+ Parameters',
  steps : [
      {
          title : 'Voice Feedback by our AI-Interviewers.',
          img : 'https://assets.languify.in/images/Feedback_1.svg'
      },
      {
          title : 'Detailed Speech & Content Parameters Feedback.',
          img : 'https://assets.languify.in/images/Feedback_2.svg'
      },
      {
          title : 'Improvement Suggestions on Student’s Response.',
          img : 'https://assets.languify.in/images/Feedback_3.svg'
      },
      {
          title : 'Example response for Situational Questions.',
          img : 'https://assets.languify.in/images/Feedback_4.svg'
      },
      {
          title : 'Ideal response for Technical Questions.',
          img : 'https://assets.languify.in/images/Feedback_5.svg'
      },
  ]
}]