import React from 'react'

import { clientImages, productBasedImages, serviceBasedImages } from 'components/B2C/Constant';
import B2cAppShell from 'components/B2cAppShell';
import B2cHeroSection from 'components/B2C/HeroSection';
import B2cCompanyTags from 'components/B2C/CompanyTags';
import StudentTestimonial from 'components/B2C/StudentTestimonial';
import UseCase from 'components/B2C/UseCase';
import B2cMockOverview from 'components/B2C/MockOverview';
import B2cFeedbackOverview from 'components/B2C/FeedbackOverview';
import PrepareInterview from 'components/B2C/PrepareInterview';
import PricingModel from 'components/B2C/PricingModel';
import B2cConfusedSection from 'components/B2C/ConfusedSection';
import Faq from 'components/B2C/Faq';

const B2C = () => {

  return (
    <B2cAppShell>
       <B2cHeroSection />
       <B2cCompanyTags topItems={productBasedImages} bottomItems={serviceBasedImages} />
       <StudentTestimonial />
       <B2cCompanyTags showTrustByStudents={true} topItems={clientImages}/>
       <UseCase/>
       <B2cMockOverview /> 
       <B2cFeedbackOverview />
       <PrepareInterview />
       <PricingModel />
       <B2cConfusedSection />
       <Faq />
    </B2cAppShell>
  )
}

export default B2C
