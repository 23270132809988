import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box, Divider, Typography } from '@mui/material';

import CustomButton from 'components/CustomButton';
import FreeTrialDialog from 'dialogs/iwFreeTrialDialog';


const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 37.5),
    paddingTop: theme.spacing(13),
    paddingBottom: theme.spacing(43),
    flexDirection: 'column',
    gap: theme.spacing(13),
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(180deg, #737DA8 0%, #2D3142 100%)',
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(14, 15),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(14, 10),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(14, 5),
    },
  },
  priceContainer: {
    display: 'flex',
    gap: theme.spacing(15),
    justifyContent: 'space-between',
    [theme.breakpoints.down("lg")]: {
      flexWrap: 'wrap',
      gap: theme.spacing(7)
    },

  },
  priceCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    padding: theme.spacing(18, 7, 5, 7),
    backgroundColor: theme.palette.shades['clr-white-900'],
    gap: theme.spacing(5),
    minWidth: '340px',
    width: '100%',
    borderRadius: theme.spacing(5),
    border: `1px solid ${theme.palette.neutral['clr-300']}`,
    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
    position: 'relative',
    minHeight : '680px',
    '&:hover': {
      boxShadow: '0px 0px 16px 2px #FFFFFFBF',
    },
  },
  chip: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.tertiary['clr-50'],
    width: 'fit-content',
  },
  chipDanger: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.danger['clr-100'],
    width: 'fit-content',
  },
  bulletPoints: {
    padding: theme.spacing(3, 1),
    display: 'flex',
    gap: theme.spacing(2)
  }
}))

const price1Benefits = [
  '1 Free trial credit',
  'Unlimited Reattempts',
  'Practice any Job Interview Round',
  'Choose AI-Interviewers by difficulty level',
  'Ideal response for your answer'
]

const price2Benefits = [
  '10 Limited credits for creating Mock Interviews',
  'Unlimited Reattempts',
  'Practice any Job Interview Round',
  'Choose AI-Interviewers by difficulty level',
  'Ideal response for your answer'
]

const price3Benefits = [
  'Unlimited Custom Mock Interviews',
  'Unlimited Reattempts',
  'Practice any Job Interview Round',
  'Choose AI-Interviewers by difficulty level',
  'Ideal response for your answer'
]

const B2cPrice = () => {
  const classes = useStyles();

  const [openFreeTrialDialog, setOpenFreeTrialDialog] = React.useState(false);

  return (
    <Box id='pricing' className={classes.container}>
      <Typography
        variant="h2-bold"
        color="b2c.light-900"
        style={{ maxWidth: "400px", textAlign: "center" }}
      >
        Priced for Students Packed with Potential
      </Typography>
      <Box className={classes.priceContainer}>

        {/* Price 1 */}
        <Box className={classes.priceCard}>
          <img
            src="https://assets.languify.in/images/priceBgArrow.png"
            alt="arrow"
            style={{ position: "absolute", top: 0, right: 0 }}
          />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h4-bold">Interview Wizard</Typography>
            <Box className={classes.chip}>
              <Typography variant="body02-bold" color="tertiary.clr-700">
                Free Trial
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            {price1Benefits.map((item, index) => (
              <React.Fragment key={index}>
                <Box className={classes.bulletPoints}>
                  <Typography variant="h6-medium" color="neutral.clr-700">
                    •
                  </Typography>
                  <Typography variant="h6-medium" color="neutral.clr-700">
                    {item}
                  </Typography>
                </Box>
                {index < price1Benefits.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Box>
          <CustomButton
            variant='outlined' size='large' sx={{ marginTop: '8px' }}
            onClick={() => setOpenFreeTrialDialog(true)}
          >
            <Typography variant='h6-medium' color='primary.main'>Try for free!</Typography>
            <img width={24} height={24} src='https://assets.languify.in/gifs/Star-struck.gif' alt='gif' />
          </CustomButton>
        </Box>

        {/* Price - 2 */}
        <Box className={classes.priceCard}>
          <Box
            style={{
              position: "absolute",
              top: -2,
              left: 0,
              overflow: "hidden",
            }}
          >
            <img
              src="https://assets.languify.in/images/tagFifty.svg"
              alt="arrow"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: '20px 0 0 0',
              }}
            />
          </Box>
          <img
            src="https://assets.languify.in/images/priceBgArrow.png"
            alt="arrow"
            style={{ position: "absolute", top: 0, right: 0 }}
          />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h4-bold">Interview Wizard</Typography>
            <Box className={classes.chip}>
              <Typography variant="body02-bold" color="tertiary.clr-700">
                1 Month Access
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            {price2Benefits.map((item, index) => (
              <React.Fragment key={index}>
                <Box className={classes.bulletPoints}>
                  <Typography variant="h6-medium" color="neutral.clr-700">
                    •
                  </Typography>
                  <Typography variant="h6-medium" color="neutral.clr-700">
                    {item}
                  </Typography>
                </Box>
                {index < price1Benefits.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', flexDirection: 'column', width: '100%', gap: '18px' }}>
            <Box display='flex' flexDirection='column' gap='8px'>
              <Box>
                <Typography variant='h5-semiBold' color='b2c.dark-900'>₹1000</Typography> &nbsp;&nbsp;
                <Typography variant='h5-semiBold' color='neutral.clr-400' sx={{ textDecoration: "line-through" }}>₹2000</Typography>
              </Box>
              <Typography variant='body01-bold' color='success.clr-500'>
                Hurry! Save ₹1000 before it’s gone!
              </Typography>
              <Box display='flex' alignItems='center' gap='4px'>
                <img width={24} height={24} src='https://assets.languify.in/gifs/Alarm-clock.gif' />
                <Typography variant='h6-bold' color='danger.clr-700'>
                  Offer is ending soon
                </Typography>
              </Box>
            </Box>
            <CustomButton
              size='large'
              variant='contained'
              sx={{
                width: '100%',
                height: '56px !important',
                backgroundColor: '#1961BF'
              }}
              onClick={() => window.open(process.env.REACT_APP_RAZORPAY_PAYMENT_LINK_BASIC, '_self')}
            >
              <Typography variant='h6-medium'>Buy Now!</Typography>
              <img
                width={24}
                height={24}
                src='https://assets.languify.in/gifs/Index-finger.gif'
                alt='gif'
                style={{
                  transform: "rotate(-90deg)",
                }}
              />

            </CustomButton>
          </Box>
        </Box>

        {/* Price - 3 */}
        <Box
          className={classes.priceCard}
          sx={{
            backgroundImage: "url('https://assets.languify.in/images/priceBg.png')",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box
            style={{
              position: "absolute",
              top: -2,
              left: 0,
              overflow: "hidden",
              clipPath: `inset(0 0 0 0 round 0 4px 0 0)`,
            }}
          >
            <img
              src="https://assets.languify.in/images/tagFifty.svg"
              alt="arrow"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: '20px 0 0 0',
              }}
            />
          </Box>
          <img
            style={{
              borderRadius: '20px',
              position: 'absolute',
              top: 0,
              right: -1
            }}
            src='https://assets.languify.in/images/b2cpattern2.png'
            alt="pattern"
          />
          <img
            style={{
              borderRadius: '20px',
              position: 'absolute',
              bottom: 0,
              left: -1,
              transform: "rotate(-180deg)",
            }}
            src='https://assets.languify.in/images/b2cpattern2.png'
            alt="pattern"
          />
          <Box display="flex" flexDirection="column" gap="8px">
            <Typography variant="h4-bold">Interview Wizard</Typography>
            <Box display='flex' gap='4px' flexWrap='wrap'>
              <Box className={classes.chip}>
                <Typography variant="body02-bold" color="tertiary.clr-700">
                  1 Month Access
                </Typography>
              </Box>
              <Box className={classes.chip}>
                <img width={20} height={20} src='https://assets.languify.in/gifs/Star-struck.gif' alt='gif' />
                <Typography variant="body02-bold" color="tertiary.clr-700">
                  Most Popular
                </Typography>
              </Box>
              <Box className={classes.chipDanger}>
                <img width={20} height={20} src='https://assets.languify.in/gifs/FireB2c.gif' alt='gif' />
                <Typography variant="body02-bold" color="danger.clr-700">
                  Hot Deal
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" width="100%">
            {price3Benefits.map((item, index) => (
              <React.Fragment key={index}>
                <Box className={classes.bulletPoints}>
                  <Typography variant="h6-medium" color="neutral.clr-700">
                    •
                  </Typography>
                  <Typography variant="h6-medium" color="neutral.clr-700">
                    {item}
                  </Typography>
                </Box>
                {index < price1Benefits.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Box>
          <Box sx={{ marginTop: '8px', display: 'flex', flexDirection: 'column', width: '100%', gap: '18px' }}>
            <Box display='flex' flexDirection='column' gap='8px'>
              <Box>
                <Typography variant='h5-semiBold' color='b2c.dark-900'>₹1500</Typography> &nbsp;&nbsp;
                <Typography variant='h5-semiBold' color='neutral.clr-400' sx={{ textDecoration: "line-through" }}>₹3000</Typography>
              </Box>
              <Typography variant='body01-bold' color='success.clr-500'>
                Hurry! Save ₹1500 before it’s gone!
              </Typography>
              <Box display='flex' alignItems='center' gap='4px'>
                <img width={24} height={24} src='https://assets.languify.in/gifs/Alarm-clock.gif' />
                <Typography variant='h6-bold' color='danger.clr-700'>
                  Offer is ending soon
                </Typography>
              </Box>
            </Box>
            <CustomButton
              size='large'
              variant='contained'
              sx={{
                width: '100%',
                height: '56px !important',
                backgroundColor: '#1961BF'
              }}
              onClick={() => window.open(process.env.REACT_APP_RAZORPAY_PAYMENT_LINK_PLUS, '_self')}
            >
              <Typography variant='h6-medium'>Buy Now!</Typography>
              <img
                width={24}
                height={24}
                src='https://assets.languify.in/gifs/Index-finger.gif'
                alt='gif'
                style={{
                  transform: "rotate(-90deg)",
                }}
              />
            </CustomButton>
          </Box>
        </Box>
      </Box>


      <FreeTrialDialog open={openFreeTrialDialog} onClose={() => setOpenFreeTrialDialog(false)} />
    </Box>
  );
};

export default B2cPrice;
